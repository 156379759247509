import React from "react";
import HeadData from "../data/HeadData";
import HomeHero from "../components/hero/HomeHero";
import Header from "../components/sections/Header";
import loadable from "@loadable/component";
import "reactjs-popup/dist/index.css";

const ProductGallery = loadable(() =>
  import("../components/home/ProductGallery")
);
const PreviewAbout = loadable(() => import("../components/home/PreviewAbout"));
const ProductFeature = loadable(() =>
  import("../components/home/ProductFeature")
);
const ValuedSuppliers = loadable(() =>
  import("../components/controls/Prismic/ValuedSuppliers")
);
const Advantages = loadable(() => import("../components/home/Advantages"));
const ContactExpert = loadable(() =>
  import("../components/controls/ContactExpert")
);
const SatisfiedCustomer = loadable(() =>
  import("../components/controls/SatisfiedCustomer")
);
const Hiring = loadable(() => import("../components/home/Hiring"));
const Footer = loadable(() => import("../components/sections/Footer"));

const IndexPage = () => {

  return (
    <div className="w-full max-w-full relative overflow-hidden">
      <HeadData isHome={true} />

      <Header isHome={true} />

      <HomeHero />

      <ProductGallery />

      <PreviewAbout />

      <ProductFeature />

      <ValuedSuppliers />

      <Advantages />

      <ContactExpert />

      <SatisfiedCustomer />

      <Hiring />

      <Footer enableRequestQuote={false} />
    </div>
  );
};

export default IndexPage;
