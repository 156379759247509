import React from "react";
import ButtonAction from "../controls/ButtonAction";

import BackgroundImage from "gatsby-background-image";
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { MdLocationOn } from "react-icons/md";

const LocationLink = styled.a`
  // text-decoration: none !important;
  color: inherit !important;
`;

const HeroBG = ({ children }) => {
  const image = useStaticQuery(graphql`
    {
      bg: file(relativePath: { eq: "nwp-banner.webp" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
          )
        }
      }
      bgSmall: file(relativePath: { eq: "nwp-banner-sm.webp" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
          )
        }
      }
    }
  `);

  return (
    <>
      <BackgroundImage
        className="hidden sm:flex relative -mt-32 w-full max-w-full min-h-800 h-screen mb-0 mx-0 p-0 flex-row bg-black bg-opacity-50 bg-blend-darken bg-cover"
        {...convertToBgImage(getImage(image.bg))}
      >
        {children}
      </BackgroundImage>

      <BackgroundImage
        className="flex sm:hidden relative -mt-32 w-full max-w-full h-75vh min-h-800 mb-0 mx-0 p-0 flex-row bg-black bg-opacity-50 bg-blend-darken bg-cover"
        {...convertToBgImage(getImage(image.bgSmall))}
      >
        {children}
      </BackgroundImage>
    </>
  );
};

const HomeHero = () => {
  return (
    <>
      <HeroBG>
        <div className="w-7/12 text-white">
          <div className="absolute w-full flex justify-center">
            <div className="flex container pt-52 pl-8 lg:pl-32">
              <div className="flex flex-col space-y-8 sm:space-y-14">
                <h1 className="font-raleway md:w-12em uppercase font-black text-white">
                  National Wood Products So Cal
                </h1>
                <span className="w-full max-w-lg">
                  Your wholesale hardwood lumber, hardwood plywood, lacquered
                  panel & melamine supplier in Southern California
                </span>
                <div className="hidden sm:block">
                  <ButtonAction
                    className="bg-actionBlue hover:bg-opacity-50 text-white px-12"
                    to="contact/"
                  >
                    Request Free Quote
                  </ButtonAction>
                </div>

                <div className="block sm:hidden absolute -right-8 -bottom-24">
                  <ButtonAction
                    className="bg-actionBlue hover:bg-opacity-50 text-white py-2 px-12"
                    to="contact/"
                  >
                    Request Free Quote
                  </ButtonAction>
                </div>
              </div>
              <div className="flex flex-col lg:flex-row items-center mx-auto mt-8 lg:mt-14 text-sm lg:text-xl text-white text-center">
                <span>Visit us in our New Location</span>
                <div className="text-red-600 text-4xl rotate">
                  <MdLocationOn />
                </div>
                <LocationLink
                  href="https://goo.gl/maps/xybmsxwNP374L1ub6"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>1 Latitude Way, </span>
                  <span>Corona, CA 92881</span>
                </LocationLink>
              </div>
            </div>
          </div>
        </div>
      </HeroBG>
    </>
  );
};

export default HomeHero;
